// AdminTopBar.js
import React from 'react';
// Import your icon components
import { BellIcon } from '@heroicons/react/outline'; 

const AdminTopBar = ({ userName, userImage, title }) => {
  return (
    <div className="flex items-center justify-between bg-white px-4 py-2 shadow-md">
      {/* Title Section */}
      <div>
        <h1 className="text-xl font-bold">{title}</h1>
      </div>

      {/* Actions and User Profile Section */}
      <div className="flex items-center space-x-4">
        {/* Notification Icon */}
        <div>
          <BellIcon className="h-6 w-6 text-black" />
        </div>

        {/* User Profile and Dropdown */}
        <div className="flex items-center space-x-2 cursor-pointer">
          <img src={userImage} alt="User profile" className="h-8 w-8 rounded-full" />
          <span className="text-sm font-medium">{userName}</span>
          {/* Dropdown Icon */}
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AdminTopBar;
