import React, { useState } from 'react';
import Footer from '../Components/Common/Footer';
import TopMenu from '../Components/Common/TopMenu';

const Reports = () => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <div className='h-screen flex flex-col'>
            <TopMenu />

        <div className='mx-auto  flex-grow'> 
            <h1 className='my-auto my-52 text-3xl '>Coming Soon</h1>
        </div>
            
            

            <Footer />
        </div>
    );
}

export default Reports