import { deleteDoc, updateDoc } from 'firebase/firestore';
import React from 'react';

const TableRow = ({ children, docRef, entry }) => {
    const updateData = async (e) => {
        if (entry[e.target.name] === e.target.value) return;
        const updatedInfo = { [e.target.name]: e.target.value };

        try {
            await updateDoc(docRef, updatedInfo);
            console.log("Document successfully updated!");
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const deleteData = async () => {
        try {
            await deleteDoc(docRef);
            console.log("Document successfully deleted!");
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    }

    return (
        <>
            {children(updateData, deleteData)}
        </>
    );
};


export default TableRow;