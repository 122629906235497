// BasicInfo.js
import React, { useEffect, useState } from 'react';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../Services/firebase-config.js';
import { useParams } from 'react-router-dom';

const BasicInfo = () => {

  const { id } = useParams();
  const [loadingStakeholderInfo, setLoadingStakeholderInfo] = useState(false);
  const [updatedInfo, setUpdatedInfo] = useState(null);
  const [stakeholderInfo, setStakeholderInfo] = useState({
    name: '',
    description: '',
    year: '',
    website: '',
    emailAddress: '',
    location: '',
    contacts: [],
  });


  const fetchStakeholder = async () => {
    setLoadingStakeholderInfo(true);
    const docRef = doc(db, 'stakeholders', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setStakeholderInfo(docSnap.data());
    } else {
      console.log("No such document!");
    }
    setLoadingStakeholderInfo(false);
  };

  useEffect(() => {
    fetchStakeholder();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStakeholderInfo({ ...stakeholderInfo, [name]: value });
    setUpdatedInfo({ ...updatedInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingStakeholderInfo(true);
    const docRef = doc(db, 'stakeholders', id);

    try {
      await updateDoc(docRef, updatedInfo);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    setLoadingStakeholderInfo(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const path = `path/to/save/${file.name}`;
    // ... your file upload logic
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...stakeholderInfo.contacts];
    updatedContacts[index] = { ...updatedContacts[index], [field]: value };
    setStakeholderInfo({ ...stakeholderInfo, contacts: updatedContacts });
  };

  // ... rest of your component
  const [contacts, setContacts] = useState({
    'contact1': { name: '', phone: '', email: '', position: '' },
  });


  let contactId = 2; // Start with 2 since 'contact1' is already used

  const addContact = () => {
    const newContactKey = `contact${contactId++}`; // Increment to ensure uniqueness
    const newContact = { [newContactKey]: { name: '', phone: '', email: '', position: '' } };

    setContacts({ ...contacts, ...newContact });
  };


  const removeContact = (contactKey) => {
    const { [contactKey]: _, ...remainingContacts } = contacts;
    setContacts(remainingContacts);
  };


  return (
    <div className='w-full  mx-auto bg-white p-8 relative'>
      {loadingStakeholderInfo && (
        <div className='absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center'>
          <div className='absolute top-0 left-0 w-full h-screen flex items-center justify-center'>
            <div className="flex justify-center items-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
            </div>
          </div>
        </div>
      )}
      <h1 className="text-2xl font-bold mb-6">Basic Information</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={stakeholderInfo.name}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border border-gray-300"
          />
        </div>
        <div>
          <label htmlFor="description" className="text-gray-700">Brief Description</label>
          <textarea
            id="description"
            name="description"
            rows="4"
            value={stakeholderInfo.description}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border border-gray-300"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="logo" className="text-gray-700">Logo</label>
            <div className="flex items-center mt-1 p-2 border border-gray-300 ">
              <button type="button" className="py-1 px-3 bg-gray-200 text-gray-700 ">Upload</button>
            </div>
          </div>
          <div>
            <label htmlFor="cover-photo" className="text-gray-700">Cover Photo</label>
            <div className="flex items-center mt-1 p-2 border border-gray-300 ">
              <button type="button" className="py-1 px-3 bg-gray-200 text-gray-700 ">Upload</button>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="year" className="text-gray-700">Year Of Establishment</label>
          <input
            type="text"
            id="year"
            name="year"
            value={stakeholderInfo.year}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border border-gray-300"
          />
        </div>
        <div>
          <label htmlFor="website" className="text-gray-700">Website</label>
          <input
            type="text"
            id="website"
            name="website"
            value={stakeholderInfo.website}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border border-gray-300"
          />
        </div>
        <div>
          <label htmlFor="email-address" className="text-gray-700 block">Email Address</label>
          <input
            type="email"
            id="email-address"
            name="emailAddress" // Make sure this matches the state object's property
            value={stakeholderInfo.emailAddress}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border border-gray-300"
          />
        </div>
        <div>
          <label htmlFor="location" className="text-gray-700 block">Location</label>
          <input
            type="text"
            id="location"
            name="location"
            value={stakeholderInfo.location}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border border-gray-300"
          />
        </div>
        {/* Contacts Section */}
        <h2 className="text-sm font-normal text-gray-700">Contact People</h2>
        {Object.entries(contacts).map(([index, contact]) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-2 items-center">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={contact.name}
              onChange={(e) => handleContactChange(index, 'name', e.target.value)}
              className="p-2 border border-gray-300"
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone No"
              value={contact.phone}
              onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
              className="p-2 border border-gray-300"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={contact.phone}
              onChange={(e) => handleContactChange(index, 'email', e.target.value)}
              className="p-2 border border-gray-300"
            />
            <input
              type="text"
              name="position"
              placeholder="Job Title"
              value={contact.phone}
              onChange={(e) => handleContactChange(index, 'position', e.target.value)}
              className="p-2 border border-gray-300"
            />
            <div>
              {contacts.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeContact(index)}
                  className="text-custom-blue-1 hover:text-custom-green-1 mr-10"
                >
                  <BsTrash className="text-xl" />
                </button>
              )}
              {index === contacts.length - 1 && (
                <button
                  type="button"
                  onClick={addContact}
                  className="text-custom-blue-1 hover:text-custom-green-1"
                >
                  <BsPlusCircle className="text-xl" />
                </button>
              )}
              {index !== contacts.length - 1 && <div></div>} {/* Empty cell for alignment */}
            </div>
          </div>
        ))}
        {/* Button group for saving or discarding changes */}
        <div className="flex justify-center mt-4">
          <button
            type="button"
            className="py-2 px-6 border border-custom-blue-1 text-custom-blue-1 hover:bg-gray-100 "
          >
            Discard Changes
          </button>
          <button
            type="submit" disabled={!updatedInfo}
            className="py-2 px-6 ml-10 bg-custom-green-1 text-white hover:bg-custom-green-1"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default BasicInfo;
