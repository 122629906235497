import React from 'react';


const MetricsSection = () => {
  return (
    <div className="bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl text-center text-custom-blue-1 font-bold mb-6">KEY METRICS</h2>
        <div className="grid grid-cols-1 bg-black md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
          <div className="col-span-1 md:col-span-2 lg:col-span-3 bg-white p-6 shadow ">
            <div className="flex  flex-col lg:flex-row -mx-2">
              {/* Metric Display */}
              <div className="px-2 w-full flex-grow md:w-1/4">
                <div className="flex items-center justify-center h-full bg-custom-blue-1 p-4 shadow-lg">
                  <div className="text-center"> {/* This container will center the text vertically and horizontally */}
                    <div className="text-white text-2xl font-bold mb-4">11,200</div>
                    <div className="text-white">COMBINED ANNUAL PRODUCTION CAPACITY</div>
                  </div>
                </div>
              </div>
              {/* Table Structure */}
              <div className="px-2 w-full md:w-3/4">
                <div className="bg-white p-2 rounded">
                  <div className="grid gap-1 grid-cols-3 ">
                    <div className="text-md text-center font-bold p-2">CATEGORY</div>
                    <div className="text-md text-center font-bold p-2">UNITS PRODUCED</div>
                    <div className="text-md text-center font-bold p-2">TOTAL MILEAGE (KM)</div>
                  </div>
                  {/* Row Item */}
                  <div className="grid gap-1 grid-cols-3 ">
                    <div className="p-2 flex items-center text-sm text-custom-blue-1 text-left  m-2">
                      <img src="./Images/icons/e-bus.svg" alt="e-bus" className="h-6 w-6 mr-2" />
                      E-BUSES</div>
                    <div className="p-2 text-lg font-semibold text-white text-center bg-custom-blue-1 rounded-none m-2">27</div>
                    <div className="p-2 text-lg font-semibold text-white text-center bg-custom-blue-1 rounded-none m-2">350,000</div>
                  </div>
                  {/* Row Item */}
                  <div className="grid gap-1 grid-cols-3 ">
                    <div className="p-2 flex items-center text-sm text-custom-blue-1 text-left  rounded-none m-2">
                      <img src="./Images/icons/e-motobike.svg" alt="e-motobike" className="h-6 w-6 mr-2" />
                      E-MOTORCYCLES</div>
                    <div className="p-2 text-lg font-semibold text-white text-center bg-custom-blue-1 rounded-none m-2">3000</div>
                    <div className="p-2 text-lg font-semibold text-white text-center bg-custom-blue-1 rounded-none m-2">62,000,000</div>
                  </div>
                  {/* Row Item */}
                  <div className="grid gap-1 grid-cols-3 ">
                    <div className="p-2 text-sm flex items-center text-custom-blue-1 text-left   rounded-none m-2">
                      <img src="./Images/icons/e-bike.svg" alt="e-motobike" className="h-6 w-6 mr-2" />
                      E-BICYCLES
                    </div>
                    <div className="p-2 text-lg font-semibold text-white text-center bg-custom-blue-1 rounded-none m-2">1,229</div>
                    <div className="p-2 text-lg font-semibold text-white text-center bg-custom-blue-1 rounded-none m-2">80,000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Column 1 */}
          <div className="flex flex-col gap-4 ">
            {/* Row 1 */}
            <div className="bg-white p-6 text-center shadow ">
              {/* Content for Card 1 */}
              {/* Card 1 */}
              <div className="font-bold  text-xl mb-2">USD 120 M</div>
              <div className="text-gray-700 text-base">FUNDING RAISED</div>
            </div>
            {/* Row 2 */}
            <div className="bg-white flex-grow p-6 text-center shadow ">
              {/* Content for Card 2 */}
              {/* Card 1 */}
              <div className="font-bold text-xl mb-2">3,717</div>
              <div className="text-gray-700 text-base">JOBS CREATED
                (CapEx: 684; OpEx: 3,033)</div>
            </div>
          </div>

          {/* Column 2 */}
          <div className="flex flex-col   gap-4">
            {/* Row 1 */}
            <div className="bg-white p-6 text-center shadow ">
              {/* Content for Card 3 */}
              <div className="font-bold text-xl mb-2">5,000,000 L</div>
              <div className="text-gray-700 text-base">FOSSIL FUEL AVOIDED</div>
            </div>
            {/* Row 2 */}
            <div className="bg-white p-6 text-center shadow ">
              {/* Content for Card 4 */}
              <div className="font-bold text-xl mb-2">148 </div>
              <div className="text-gray-700 text-base">CHARGING & SWAPPING STATIONS</div>
              <div className="mt-4 flex justify-center">
                <button className='button-primary flex items-center justify-center'>
                  View on Map
                  <img src="./Images/icons/maps.svg" alt="maps icon" className="h-6 w-6 ml-2" />
                </button>
              </div>
            </div>
          </div>

          {/* Column 3 */}
          <div className="bg-custom-blue-1 opacity-58 p-6 text-left shadow  row-span-2">
            {/* Content for the large Card that spans two rows */}
            {/* Content for Card 3 */}
            <div className="font-bold text-white text-xl mb-2">3,853 Metric Tons</div>
            <div className="text-white text-base">ESTIMATED CO2EQ AVOIDED</div>
            <img src="./Images/Data.svg" alt="E-mobility Data" className=" m-4" />
          </div>
        </div>

      </div>
    </div>
  );

};

export default MetricsSection;
