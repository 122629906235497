import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { signOut } from 'firebase/auth'; // Import signOut function
import { auth } from "../../Services/firebase-config";
// Import the icons from Heroicons or any other icon library you prefer
import { HomeIcon, UsersIcon, ClipboardListIcon, CogIcon } from '@heroicons/react/outline';

const SideNav = ({ children }) => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await signOut(auth); // Sign out from Firebase
            navigate('/'); // Redirect to the homepage after sign out
        } catch (error) {
            console.error("Logout failed", error);
            // Handle errors here, such as displaying a notification
        }
    };
    return (
        <div className='flex h-screen  '>
            <div className="w-64 h-screen bg-custom-blue-1 flex flex-col justify-between">
                <div>
                    <div className="flex items-center justify-center pt-6 pb-8">
                        <img src="./Images/logo-light.svg" alt="E-Mobility Data Bank" className="h-12" />
                    </div>
                    {/* Separator */}
                    <hr className="border-t border-gray-200" />
                    <ul className="flex flex-col mt-5 space-y-2">
                        <li>
                            <NavLink to="/admin"
                            end // add this prop here
                                className={({ isActive }) =>
                                    `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                                    }`
                                }
                            >
                                <HomeIcon className="w-6 h-6" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/stakeholders"
                                className={({ isActive }) =>
                                    `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                                    }`
                                }
                            >
                                <UsersIcon className="w-6 h-6" />
                                <span>Stakeholders</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/reports"
                                className={({ isActive }) =>
                                    `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                                    }`
                                }
                            >
                                <ClipboardListIcon className="w-6 h-6" />
                                <span>Reports</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/settings"

                                className={({ isActive }) =>
                                    `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                                    }`
                                }
                            >
                                <CogIcon className="w-6 h-6" />
                                <span>Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                {/* Logout Button */}
                <div className="p-4 w-full flex justify-center items-end">
                    <button
                        onClick={handleLogout}
                        className="flex items-center justify-center px-4 py-2 bg-transparent text-white  hover:bg-custom-green-1 ">
                        {/* Replace the arrow icon with your logout icon */}
                        <img src="./Images/icons/logout.svg" alt="e-bus" className="h-6 w-6 mr-2 " />
                        LOG OUT
                    </button>
                </div>

            </div>
            
            <div className="flex-1">
                {children}
            </div>
        </div>

    );
};

export default SideNav;
