// DashboardContent.js
import React from 'react';
import CardComponent from './CardComponent';
import AdminTopBar from './AdminTopBar';


const DashboardContent = () => {
    // Dummy data for the cards
    const cardData = [
      { icon: "./Images/icons/rnd.svg",   title: 'R&D and Manufacturing', percentage: 70 },
      { icon: "./Images/icons/energy.svg", title: 'Energy', percentage: 70 },
      { icon: "./Images/icons/transport.svg", title: 'Transport & Distribution', percentage: 70 },
      { icon: "./Images/icons/Financing.svg", title: 'Financing', percentage: 70 },
      { icon: "./Images/icons/digital.svg", title: 'Digital Infrastructure', percentage: 70 },
      { icon: "./Images/icons/policy.svg", title: 'Policy', percentage: 70 },
      
      // ... other cards
    ];
  return (
    <>
     <AdminTopBar title="DashBoard" userName="Jane Doe" userImage="./Images/STIlogo.svg" />
    <div className="p-5 w-full  ">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4  mt-10 h-full">
        {cardData.map((card, index) => (
          <CardComponent key={index} icon={card.icon} title={card.title} percentage={card.percentage} />
        ))}
      </div>
    </div>
    </>
  );
};

export default DashboardContent;
