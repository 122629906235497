// ChargingInfrastructure.js
import React, { useEffect, useState } from 'react';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import TableRow from '../../Common/TableRow';
import { addDoc, collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../Services/firebase-config';
import { useParams } from 'react-router-dom';
import Loading from '../../Common/Loading';

const ChargingInfrastructure = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [chargers, setChargers] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);


  const addCharger = async () => {
    const data = { Rating: '', Quantity: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'chargers'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const addChargingStation = async () => {
    const data = { date: '', type: '', longitude: '', latitude: '', totalPowerRating: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'chargingStations'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const fetchChargers = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "chargers"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setChargers([...data]);
    });

    return unsubscribe;
  };

  const fetchChargingStations = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "chargingStations"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setChargingStations(data);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchChargers();
    const unsubscribe2 = fetchChargingStations();
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <div className='w-full bg-white p-8'>
      {/* Your dashboard content here */}
      <h1 className="text-2xl font-bold mb-6">Charging Infrastructure</h1>

      <h2 className="text-gray-700 block mb-2">Chargers</h2>
      {chargers.map((entry, index) => {
        return (
          <ChargerRow key={index} entry={entry} stakeholderId={id} />
        )
      })}

      <Loading loading={loading} />

      <button onClick={addCharger} className="bg-custom-green-1 text-white px-4 py-2 mt-4">
        Add Row
      </button>

      <h2 className="text-gray-700 block mb-6 mt-10">Charging Stations</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 bg-white">
          <thead className="bg-custom-blue-1">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Longitude</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Latitude</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Total Power Rating</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {chargingStations.map((entry, index) => (
              <ChargingStationRow key={index} entry={entry} stakeholderId={id} />
            ))}
          </tbody>

        </table>

        <Loading loading={loading} />

        <button onClick={addChargingStation} className="bg-custom-green-1 text-white px-4 py-2">
          Add Entry
        </button>
      </div>
    </div>
  );
};


const ChargerRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'chargers', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-2 items-center">
        <input
          type="text"
          placeholder="Rating"
          name='rating'
          className="p-2 border border-gray-300 col-span-2"
          defaultValue={entry.rating}
          onBlur={updateData}
        />
        <input
          type="number"
          placeholder="Quantity"
          name='quantity'
          className="p-2 border border-gray-300 col-span-1"
          defaultValue={entry.quantity}
          onBlur={updateData}
        />

        <div className="flex ">
          <button onClick={deleteData} className="text-custom-blue-1 hover:text-custom-green-1">
            <BsTrash className="text-xl" />
          </button>
        </div>
      </div>)}
  </TableRow>
}

const ChargingStationRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'chargingStations', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <tr>
        <td className="">
          <input
            type="date"
            name='date'
            className="w-full text-sm px-6 py-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <select
            className="w-full text-sm px-6 py-4"
            name='type'
            defaultValue={entry.type}
            onBlur={updateData}
          >
            <option value="Funding Raised">AC</option>
            <option value="Funding Raised">DC</option>
          </select>
        </td>
        <td className="">
          <input
            type="number"
            name='longitude'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.longitude}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <input
            type="number"
            name='latitude'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.latitude}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <input
            type="number"
            name='totalPowerRating'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.totalPowerRating}
            onBlur={updateData}
          />
        </td>
        <td>
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </td>
      </tr>)}
  </TableRow>
}

export default ChargingInfrastructure;