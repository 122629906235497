// SearchFilterComponent.js
import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/solid';

const SearchFilterComponent = () => {
    return (
        <div className="w-2/4 bg-custom-blue-1 bg-opacity-65 flex items-center space-x-2">

            <div className="bg-custom-green-1 my-1 mx-2 flex justify-center items-center">
                <button className="bg-transparent px-2 py-1 flex items-center justify-center text-custom-blue-1 focus:outline-none text-sm">
                    All <XIcon className="w-3 h-3 text-custom-blue-1 ml-1" />
                </button>
            </div>

            <span className="border-l-2 border-gray-300"></span>

            <div className="flex flex-1 bg-transparent items-center">
                <SearchIcon className="w-5 h-5 text-white ml-1" />
                <input
                    className="flex-1 bg-transparent pl-2 pr-1 py-2 placeholder-white text-white focus:outline-none"
                    type="search"
                    placeholder="Search"
                />
            </div>
        </div>
    );
};

export default SearchFilterComponent;
