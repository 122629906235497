// ReportsContent.js
import React from 'react';
import AdminTopBar from '../AdminTopBar';

const StakeholderDetails = () => {
  return (
    <>
      <AdminTopBar title="Overview" userName="Jane Doe" userImage="./Images/STIlogo.svg" />

      
      </>
  );
};

export default StakeholderDetails;