import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../../Services/firebase-config';
import { useParams } from 'react-router-dom';
import { BsTrash } from 'react-icons/bs';


const Sales = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);


  const addSalesProductionEntry = async () => {
    const salesProductionData = { date: '', product: '', salesVolume: '', productionVolume: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'sales'), salesProductionData);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const [products, setProducts] = useState(['Product 1', 'Product 2', 'Product 3']);


  const fetchSales = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "sales"), (snapshot) => {
      const salesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setSales([...salesData]);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchSales();
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className='w-full p-8'>
      {/* Sales and Production Volume Table */}
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">Sales and Production Volume</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-custom-blue-1">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Product</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Sales Volume</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Production Volume</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sales.map((entry, index) => (
            <SalesRow key={index} {...{ entry, products }} stakeholderId={id} />
          ))}
        </tbody>

      </table>

      {loading && (
        <div className="flex justify-center items-center h-24">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
        </div>
      )}

      <button onClick={addSalesProductionEntry} className="bg-custom-green-1 text-white px-4 py-2 mt-4">
        Add Row
      </button>

    </div>
  );
};

const SalesRow = ({ entry, products, stakeholderId }) => {
  console.log(entry)

  const updateData = async (e) => {
    if (entry[e.target.name] === e.target.value) return;
    const updatedInfo = { [e.target.name]: e.target.value };
    const docRef = doc(db, 'stakeholders', stakeholderId, 'sales', entry.id);
    console.log(updatedInfo);

    try {
      await updateDoc(docRef, updatedInfo);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const deleteData = async () => {
    const docRef = doc(db, 'stakeholders', stakeholderId, 'sales', entry.id);
    try {
      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  }

  return <tr>
    {/* Date input */}
    <td className="">
      <input
        type="date"
        name='date'
        className="w-full text-sm px-6 py-4"
        defaultValue={entry.date}
        onBlur={updateData}
      />
    </td>
    {/* Product dropdown */}
    <td className="">
      <select
        className="w-full text-sm px-6 py-4"
        name='product'
        defaultValue={entry.product}
        onBlur={updateData}
      >
        {products.map((product) => (
          <option key={product} value={product}>{product}</option>
        ))}
      </select>
    </td>
    {/* Sales Volume input */}
    <td className="">
      <input
        type="number"
        name='salesVolume'
        className="w-full text-sm px-2 py-4"
        defaultValue={entry.salesVolume}
        onBlur={updateData}
      />
    </td>
    {/* Production Volume input */}
    <td className="">
      <input
        type="number"
        name='productionVolume'
        className="w-full text-sm px-2 py-4"
        defaultValue={entry.productionVolume}
        onBlur={updateData}
      />
    </td>
    <td>
      <button
        onClick={deleteData}
        className="text-custom-blue-1 hover:text-custom-green-1"
      >
        <BsTrash className="text-xl " />
      </button>
    </td>
  </tr>
}

export default Sales;
