// Planning.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../Services/firebase-config';
import { addDoc, collection, doc, onSnapshot } from 'firebase/firestore';
import TableRow from '../../Common/TableRow';
import { BsTrash } from 'react-icons/bs';
import Loading from '../../Common/Loading';

const Planning = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [needs, setNeeds] = useState([]);
  const [annualTargets, setAnnualTargets] = useState([]);


  const addNeeds = async () => {
    const data = { date: '', product: '', needsVolume: '', productionVolume: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'needs'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };


  const addAnnualTargets = async () => {
    const data = { date: '', typeOfTax: '', amount: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'annualTargets'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const fetchNeeds = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "needs"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setNeeds(data);
    });

    return unsubscribe;
  };

  const fetchAnnualTargets = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "annualTargets"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setAnnualTargets(data);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchNeeds();
    const unsubscribe2 = fetchAnnualTargets();
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);


  return (
    <div className='w-full bg-white p-8'>
      {/* Your dashboard content here */}
      <h1 className="text-2xl font-bold text-custom-blue-1 mb-6">Planning</h1>
      {/* Needs Form */}
      <div className="mb-6">
        <h2 className="text-lg font-bold text-custom-blue-1 mb-4">Needs</h2>
        {needs.map((need, index) => (
          <NeedsRow key={index} entry={need} stakeholderId={id} />
        ))}

        <Loading loading={loading} />

        <button onClick={addNeeds} className="bg-custom-green-1 text-white px-4 py-2 ">
          Add Row
        </button>
      </div>

      {/* Annual Targets Form */}
      <div className="mb-6">
        <h2 className="text-lg font-bold text-custom-blue-1 mb-4">Annual Targets</h2>
        {annualTargets.map((target, index) => (
          <AnnualTargetsRow key={index} entry={target} stakeholderId={id} />
        ))}

        <Loading loading={loading} />

        <button onClick={addAnnualTargets} className="bg-custom-green-1 text-white px-4 py-2 ">
          Add Row
        </button>
      </div>

    </div>
  );
};



const NeedsRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'needs', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <div className="flex flex-wrap mb-4">
        <div className='flex justify-between w-full'>
          <input
            type="date"
            name='date'
            className="p-2 border border-gray-300  mr-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </div>
        <textarea
          rows="2"
          className="p-2 border border-gray-300  w-full"
          name='detail'
          defaultValue={entry.detail}
          placeholder="Enter needs"
          onBlur={updateData}
        />
      </div>)}
  </TableRow>
}

const AnnualTargetsRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'annualTargets', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <div className="flex flex-wrap mb-4">
        <div className='flex justify-between w-full'>
          <input
            type="number"
            min="2020"
            max="2100"
            step="1"
            placeholder='Year'
            name='year'
            className="p-2 border border-gray-300  mr-4"
            defaultValue={entry.year}
            onBlur={updateData}
          />
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </div>
        <textarea
          rows="2"
          className="p-2 border border-gray-300  w-full"
          name='detail'
          defaultValue={entry.detail}
          placeholder="Enter annual targets"
          onBlur={updateData}
        />
      </div>)}
  </TableRow>
}

export default Planning;