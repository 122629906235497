import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import TopMenu from './Components/Common/TopMenu';
import ChargingNetwork from './Pages/ChargingNetwork'; // Make sure to define this component
import Reports from './Pages/Reports'; // Make sure to define this component
import DashboardPage from './Pages/Admin/Dashboard';
import LoginPage from './Pages/Admin/LoginPage';
import StakeholdersContent from './Components/Admin/StakeholdersContent'; // Define this component
import ReportsContent from './Components/Admin/ReportsContent'; // Define this component
import SettingsContent from './Components/Admin/SettingsContent';
import SideNav from "./Components/Admin/SideNav";
import DashboardContent from "./Components/Admin/DashboardContent";
import StakeholderPage from './Pages/Admin/StakeholderPage';
import StakeholderDetails from './Components/Admin/stakeholder/StakeholderDetails';
import StakeholderSideNav from "./Components/Admin/stakeholder/StakeholderSideNav";
import BasicInfo from "./Components/Admin/stakeholder/BasicInfo";
import Operations from "./Components/Admin/stakeholder/Operations";
import Sales from "./Components/Admin/stakeholder/Sales/Sales";
import HumanResource from "./Components/Admin/stakeholder/HumanResource";
import Financials from "./Components/Admin/stakeholder/Financials";
import ChargingInfrastructure from "./Components/Admin/stakeholder/ChargingInfrastructure";
import Energy from "./Components/Admin/stakeholder/Energy";
import Planning from "./Components/Admin/stakeholder/Planning";
import Ebus from "./Pages/Ebus";
// import UploadDrivers from "./Pages/uploadDrivers";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/charging-network" element={<ChargingNetwork />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/ebus" element={<Ebus />} />
        {/* <Route path="/uploadDrivers" element={<UploadDrivers />} /> */}

        {/* Admin Routes Nested */}
        <Route path="/admin/*" element={<SideNav>
          <Routes>
            <Route index element={<DashboardContent />} />
            <Route path="stakeholders" element={<StakeholdersContent />} />
            <Route path="reports" element={<ReportsContent />} />
            <Route path="settings" element={<SettingsContent />} />
          </Routes>
        </SideNav>}>
          {/* If DashboardPage includes <Outlet />, these nested routes will render there */}
        </Route>
        // Inside your Router and Routes...
        <Route path="/stakeholder/:id/*" element={
          <StakeholderSideNav>
            <Routes>
              <Route index element={<StakeholderDetails />} />
              <Route path="details" element={<StakeholderDetails />} />
              <Route path="basicinfo" element={<BasicInfo />} />
              <Route path="operations" element={<Operations />} />
              <Route path="sales" element={<Sales />} />
              <Route path="humanresource" element={<HumanResource />} />
              <Route path="financials" element={<Financials />} />
              <Route path="charginginfrastructure" element={<ChargingInfrastructure />} />
              <Route path="energy" element={<Energy />} />
              <Route path="planning" element={<Planning />} />
            </Routes>
          </StakeholderSideNav>
        }>

          {/* Define more stakeholder-specific routes here */}
        </Route>

        {/* ... other routes if you have ... */}
      </Routes>
    </Router>
  );
}

export default App;

