// CardComponent.js
import React from 'react';

const CardComponent = ({ icon, title, percentage }) => {

    return (
        <div className="bg-white p-4 rounded-0 gap-2 shadow-md flex flex-col h-full">
        <div className="flex flex-col items-center flex-grow">
                {/* Icon will go here - For now, we'll just leave a placeholder */}
                <img src={icon} alt={`${title} icon`} className="h-12 w-12" />
                <h2 className="text-lg font-normal mt-3">{title}</h2>
                {/*Separator */}
                <div className="w-full border-t border-gray-200 my-2"></div>

                <div className="grid grid-cols-[4fr_1fr] items-center gap-2 w-full">
                    <div className="bg-gray-300 h-2.5  overflow-hidden">
                        <div className="bg-green-500 h-2.5" style={{ width: `${percentage}%` }}></div>
                    </div>
                    <div className="text-right">
                        <span className="text-sm font-medium">{`${percentage}%`}</span>
                    </div>
                </div>
                <div className="grid grid-cols-[4fr_1fr] items-center gap-2 w-full">
                    <div className="bg-gray-300 h-2.5  overflow-hidden">
                        <div className="bg-red-500 h-2.5" style={{ width: `${100 - percentage}%` }}></div>
                    </div>
                    <div className="text-right">
                        <span className="text-sm font-medium">{`${100 - percentage}%`}</span>
                    </div>
                </div>
   
            </div>
        </div>
    );
};

export default CardComponent;
