import React from 'react';
import { FaFacebookF, FaYoutube, FaInstagram} from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";



const Footer = () => {
    return (
        <footer className="bg-custom-blue-1 text-white p-4">
            <div className="container mx-auto flex flex-col md:flex-row gap-5 justify-between items-center">
                <div>
                    <a href="/privacy-policy" className="text-gray-400 hover:text-gray-300 mx-2">Privacy Policy</a>
                    <a href="/terms-and-conditions" className="text-gray-400 hover:text-gray-300 mx-2">Terms & Conditions</a>
                </div>
                <div className='flex space-x-3'>
                    <span className="text-gray-400 mx-2">Connect with Us</span>
                    <FaFacebookF className="inline mx-1 size-5" />
                    <FaXTwitter className="inline mx-1 size-5" />
                    <FaYoutube className="inline mx-1 size-5" />
                    <FaInstagram className="inline mx-1 size-5" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
