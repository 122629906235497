// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import for Firebase Storage
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvnO4kdJ9DrtltEbFIdXzNZi5Xf7cqc98",
  authDomain: "e-mobility-data-bank-app.firebaseapp.com",
  projectId: "e-mobility-data-bank-app",
  storageBucket: "e-mobility-data-bank-app.appspot.com",
  messagingSenderId: "526381145806",
  appId: "1:526381145806:web:b66f6e4d5e5689dc113a9f",
  measurementId: "G-KH4D0L3P86"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const db = getFirestore(app);
// Initialize Firebase Storage
const storage = getStorage(app);

export {db, storage };

