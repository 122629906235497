import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { db } from '../../../Services/firebase-config';
import { useParams } from 'react-router-dom';
import Loading from '../../Common/Loading';

const HumanResource = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [directJobs, setDirectJobs] = useState([]);
  const [indirectJobs, setIndirectJobs] = useState([]);


  const addDirectJob = async () => {
    const data = { date: '', females: '', males: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'directJobs'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const addInDirectJob = async () => {
    const data = { date: '', quantity: '' }
    try {
      await addDoc(collection(db, "stakeholders", id, 'inDirectJobs'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const fetchDirectjobs = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "directJobs"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setDirectJobs([...data]);
    });

    return unsubscribe;
  };

  const fetchInDirectjobs = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "inDirectJobs"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setIndirectJobs(data);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchDirectjobs();
    const unsubscribe2 = fetchInDirectjobs();
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <div className='w-full bg-white p-8'>
      <h1 className="text-2xl font-bold text-custom-blue-1 mb-6">Human Resources</h1>
      {/* Products and Services */}
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">Indirect Jobs Created</h2>
      {indirectJobs.map((entry, index) => {
        return <InDirectJobsRow key={index} {...{ entry }} stakeholderId={id} />
      }
      )}

      <Loading loading={loading} />

      <button onClick={addInDirectJob} className="bg-custom-green-1 text-white px-4 py-2 mt-4">
        Add Row
      </button>

      {/* Direct Jobs Created Table */}
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">Direct Jobs Created</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-custom-blue-1">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Females</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Males</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {directJobs.map((entry, index) => (
            <DirectJobsRow key={index} {...{ entry }} stakeholderId={id} />
          ))}
        </tbody>

      </table>

      <Loading loading={loading} />

      <button onClick={addDirectJob} className="bg-custom-green-1 text-white px-4 py-2 mt-4">
        Add Row
      </button>

    </div>

  );
};

const InDirectJobsRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'inDirectJobs', entry.id);

  const updateData = async (e) => {
    if (entry[e.target.name] === e.target.value) return;
    const updatedInfo = { [e.target.name]: e.target.value };
    console.log(updatedInfo);

    try {
      await updateDoc(docRef, updatedInfo);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const deleteData = async () => {
    try {
      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  }

  return <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-2 items-center">
    <input
      type="date"
      placeholder="Date"
      name='date'
      className="p-2 border border-gray-300 col-span-2"
      defaultValue={entry.date}
      onBlur={updateData}
    />
    <input
      type="number"
      placeholder="Quantity"
      name='quantity'
      className="p-2 border border-gray-300 col-span-1"
      defaultValue={entry.quantity}
      onBlur={updateData}
    />

    <div className="flex ">
      <button onClick={deleteData} className="text-custom-blue-1 hover:text-custom-green-1">
        <BsTrash className="text-xl" />
      </button>
    </div>
  </div>
}


const DirectJobsRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'directJobs', entry.id);

  const updateData = async (e) => {
    const updatedInfo = { [e.target.name]: e.target.value };
    console.log(updatedInfo);

    try {
      await updateDoc(docRef, updatedInfo);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const deleteData = async () => {
    try {
      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  }

  return <tr>
    {/* Date input */}
    <td className="">
      <input
        type="date"
        name='date'
        className="w-full text-sm px-6 py-4"
        defaultValue={entry.date}
        onBlur={updateData}
      />
    </td>
    <td className="">
      <input
        type="number"
        name='females'
        className="w-full text-sm px-2 py-4"
        defaultValue={entry.females}
        onBlur={updateData}
      />
    </td>
    {/* Production Volume input */}
    <td className="">
      <input
        type="number"
        name='males'
        className="w-full text-sm px-2 py-4"
        defaultValue={entry.males}
        onBlur={updateData}
      />
    </td>
    <td>
      <button
        onClick={deleteData}
        className="text-custom-blue-1 hover:text-custom-green-1"
      >
        <BsTrash className="text-xl " />
      </button>
    </td>
  </tr>
}


export default HumanResource;

