// Energy.js
import React, { useEffect, useState } from 'react';
import TableRow from '../../Common/TableRow';
import { db } from '../../../Services/firebase-config';
import { addDoc, collection, doc, onSnapshot } from 'firebase/firestore';
import { BsTrash } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Loading from '../../Common/Loading';

const Energy = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [energyConsumption, setEnergyConsumption] = useState([]);
  const [sustainabilityMetrics, setSustainabilityMetrics] = useState([]);
  const [eMobilityMileage, setEMobilityMileage] = useState([]);


  const addEnergyConsumption = async () => {
    const data = { date: '', productionEnergy: '', chargingEnergy: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'energyConsumption'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };


  const addSustainabilityMetrics = async () => {
    const data = { date: '', fuelAvoided: '', co2Avoided: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'sustainabilityMetrics'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const addEMobilityMileage = async () => {
    const data = { date: '', product: '', mileage: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'eMobilityMileage'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const fetchEnergyConsumption = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "energyConsumption"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setEnergyConsumption(data);
    });

    return unsubscribe;
  };

  const fetchSustainabilityMetrics = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "sustainabilityMetrics"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setSustainabilityMetrics(data);
    });

    return unsubscribe;
  };

  const fetchEMobilityMileage = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "eMobilityMileage"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setEMobilityMileage(data);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchEnergyConsumption();
    const unsubscribe2 = fetchSustainabilityMetrics();
    const unsubscribe3 = fetchEMobilityMileage();
    return () => {
      unsubscribe();
      unsubscribe2();
      unsubscribe3();
    };
  }, []);

  return (
    <div className='w-full bg-white p-8'>
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Energy Consumption</h1>
      <table className="min-w-full divide-y divide-gray-200 bg-white">
        <thead className="bg-custom-blue-1">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Production Energy</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Charging Energy</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {energyConsumption.map((entry, index) => (
            <EnergyConsumptionRow key={index} entry={entry} stakeholderId={id} />
          ))}
        </tbody>
      </table>

      <Loading loading={loading} />

      <button onClick={addEnergyConsumption} className="bg-custom-green-1 text-white px-4 py-2 mt-4">
        Add Row
      </button>

      {/* Below the existing Energy Consumption table */}
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">Sustainability Metrics</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-custom-blue-1">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Fuel Avoided</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">CO2eq Avoided</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sustainabilityMetrics.map((entry, index) => (
            <SustainabilityMetricsRow key={index} entry={entry} stakeholderId={id} />
          ))}
        </tbody>
      </table>

      <Loading loading={loading} />

      <button onClick={addSustainabilityMetrics} className="bg-custom-green-1 text-white px-4 py-2  mt-4">
        Add Row
      </button>

      {/* E-mobility Mileage Table */}
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">E-mobility Mileage</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-custom-blue-1">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Product</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Mileage</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {eMobilityMileage.map((entry, index) => (
            <EmobilityRow key={index} entry={entry} stakeholderId={id} />
          ))}
        </tbody>
      </table>

      <Loading loading={loading} />

      <button onClick={addEMobilityMileage} className="bg-custom-green-1 text-white px-4 py-2 mt-4">
        Add Row
      </button>
    </div>
  );
};


const EnergyConsumptionRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'energyConsumption', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <tr>
        <td className="">
          <input
            type="date"
            name='date'
            className="w-full text-sm px-6 py-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <input
            type="text"
            name='productionEnergy'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.productionEnergy}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <input
            type="number"
            name='chargingEnergy'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.chargingEnergy}
            onBlur={updateData}
          />
        </td>
        <td>
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </td>
      </tr>)}
  </TableRow>
}

const SustainabilityMetricsRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'sustainabilityMetrics', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <tr>
        <td className="">
          <input
            type="date"
            name='date'
            className="w-full text-sm px-6 py-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <input
            type="text"
            name='fuelAvoided'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.fuelAvoided}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <input
            type="number"
            name='co2Avoided'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.co2Avoided}
            onBlur={updateData}
          />
        </td>
        <td>
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </td>
      </tr>)}
  </TableRow>
}

const EmobilityRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'eMobilityMileage', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <tr>
        <td className="">
          <input
            type="date"
            name='date'
            className="w-full text-sm px-6 py-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <select
            className="w-full text-sm px-6 py-4"
            name='product'
            defaultValue={entry.product}
            onBlur={updateData}
          >
            <option value="Product 1">Product 1</option>
            <option value="Product 2">Product 2</option>
            <option value="Product 3">Product 3</option>
          </select>
        </td>
        <td className="">
          <input
            type="number"
            name='mileage'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.mileage}
            onBlur={updateData}
          />
        </td>
        <td>
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </td>
      </tr>)}
  </TableRow>
}


export default Energy;