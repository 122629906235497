import React from 'react';

const Loading = ({ loading }) => {
    return <>
        {loading && (
            <div className="flex justify-center items-center h-24">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
            </div>
        )}
    </>
}

export default Loading;