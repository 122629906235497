import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from '../Services/firebase-config'; // Adjust the path as necessary
import { collection, query, where, getDocs } from 'firebase/firestore';
import { format } from 'date-fns';

const Ebus = () => {
  const [name, setName] = useState('');
  const [graduationDate, setGraduationDate] = useState(null);
  const [isValid, setIsValid] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name && graduationDate) {
      const month = format(graduationDate, 'MMMM');
      const year = format(graduationDate, 'yyyy');

      const q = query(
        collection(db, 'drivers'),
        where('name', '==', name),
        where('monthOfCompletion', '==', month),
        where('yearOfCompletion', '==', parseInt(year))
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url('Images/ebus.jpeg')` }}>
      <div className="absolute inset-0 bg-custom-blue-1 opacity-50"></div>
      <div className="relative bg-custom-blue-1 p-8 shadow-md w-full max-w-md">
        <div className="flex justify-center mb-8">
          <img className="w-20" src={"./Images/STIlogo.svg"} alt="STI Logo" />
        </div>
        <h1 className="text-2xl text-white text-center font-bold mb-6">E-Bus Driver Validation</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 mt-1"
              required
            />
          </div>
          <div className="mb-4 w-full flex mt-6 justify-between items-center">
            <label className="block text-white">Graduation Date</label>
            <DatePicker
              selected={graduationDate}
              onChange={(date) => setGraduationDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="w-full p-2 border border-gray-300 mt-1"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-custom-green-1 text-white p-2 mt-6 hover:bg-blue-600"
          >
            Validate
          </button>
        </form>
        {isValid !== null && (
          <div
            className={`mt-4 p-4 ${
              isValid ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
            }`}
          >
            {isValid ? `${name} is Valid` : 'Certificate Invalid'}
          </div>
        )}
      </div>
    </div>
  );
};

export default Ebus;