import React from 'react';
import TopMenu from '../Components/Common/TopMenu';
import Footer from '../Components/Common/Footer';

const ChargingNetwork = () => {
    return (
        <div>
            <TopMenu />
            <div className='h-screen flex flex-col'>
                <div className="bg-custom-blue-1 text-white p-4">
                    <div className="container mx-auto flex flex-col md:flex-row gap-5 justify-between items-center">
                        <div className='flex space-x-5 items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                            <input type='text' placeholder='Search Maps' className='bg-transparent border-0 outline-none flex-grow md:flex-none' />
                        </div>
                        <div className='flex space-x-5'>
                            <button className='button-primary'>All</button>
                            <button className='button-secondary'>
                                <span className='sm:hidden'>Public</span>
                                <span className='hidden sm:inline'>Public Charging Stations</span>
                            </button>
                            <button className='button-secondary'>
                                <span className='sm:hidden'>Swapping</span>
                                <span className='hidden sm:inline'>Swapping Stations</span>
                            </button>
                        </div>
                    </div>
                </div>
                <iframe className='flex-grow w-full' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d9489.268993288912!2d32.555203593169125!3d0.3446445732061888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sug!4v1708201942264!5m2!1sen!2sug" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <Footer />
            </div>
        </div>
    );
}

export default ChargingNetwork