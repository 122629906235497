// StakeholdersContent.js
import React, { useEffect, useState } from 'react';
import AdminTopBar from './AdminTopBar';
import SearchFilterComponent from './SearchFilterComponent';
import { PlusIcon } from '@heroicons/react/solid';
import ModalComponent from './ModalComponent'; // Import the Modal component
import Select from 'react-select';
import { db } from '../../Services/firebase-config';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const StakeholdersContent = () => {
  const navigate = useNavigate();

  const [stakeholders, setStakeholders] = useState([]);
  const [loadingStakeholders, setLoadingStakeholders] = useState(false);
  const [loadingAddStakeholders, setLoadingAddStakeholders] = useState(false);

  const fetchStakeholders = async () => {
    setLoadingStakeholders(true);
    const querySnapshot = await getDocs(collection(db, "stakeholders"));
    const stakeholdersData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setLoadingStakeholders(false);
    setStakeholders(stakeholdersData);
  };

  useEffect(() => {
    fetchStakeholders();
  }, []);

  const handleRowClick = (stakeholderId) => {
    // Navigation logic here, for example:
    navigate(`/stakeholder/${stakeholderId}`);
  };

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };



  const uploadFileAndGetURL = async (file, path) => {
    const storage = getStorage();
    const storageRef = ref(storage, path);

    // Upload file
    await uploadBytes(storageRef, file);

    // Get file URL
    const url = await getDownloadURL(storageRef);
    return url;
  };

  // State for storing selected files
  const [logoFile, setLogoFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);

  // Handlers for file selection
  const handleLogoChange = (event) => {
    setLogoFile(event.target.files[0]); // Assuming single file selection
  };

  const handleCoverChange = (event) => {
    setCoverFile(event.target.files[0]); // Assuming single file selection
  };

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // Function to open the modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const productOptions = [
    { value: 'product1', label: 'Product 1' },
    { value: 'product2', label: 'Product 2' },
    { value: 'product3', label: 'Product 3' },
    // Add more options as needed
  ];
  const stakeholerOptions = [
    { value: 'rnd', label: 'R&D Manufacturing' },
    { value: 'energy', label: 'Energy' },
    { value: 'transport', label: 'Transport & Distribution' },
    { value: 'financing', label: 'Financing' },
    { value: 'digital', label: 'Digital Infrastructure' },
    { value: 'policy', label: 'Policy' },
    // Add more options as needed
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0', // Set border radius to 0 for sharp corners
      '&:hover': {
        borderColor: 'var(--custom-green-1)', // Use CSS variable for border color on hover
      }
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0', // Set border radius to 0 for the dropdown menu
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '0', // Set border radius to 0 for selected option tags
    }),
    // You can add more style overrides for other parts as needed
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action
    setLoadingAddStakeholders(true);
    // Upload files and get URLs
    const logoUrl = logoFile ? await uploadFileAndGetURL(logoFile, `logos/${logoFile.name}`) : '';
    const coverUrl = coverFile ? await uploadFileAndGetURL(coverFile, `covers/${coverFile.name}`) : '';
    // Prepare the document data
    const stakeholderData = {
      name: event.target.name.value,
      description: event.target.description.value,
      ecosystem: selectedCategory.map(option => option.value),
      products: selectedProducts.map(option => option.value),
      media: {
        logo: logoUrl,
        cover: coverUrl,
      }
    };

    try {
      // Add a new document with a generated id to the "stakeholders" collection
      await addDoc(collection(db, "stakeholders"), stakeholderData);
      // On successful submission:
      setName('');
      setDescription('');
      setLogoFile(null);
      setCoverFile(null);
      setSelectedProducts([]);
      setSelectedCategory([]);
      closeModal(); // Close the modal after submission
    } catch (error) {
      console.error("Error writing document: ", error);
    }
    setLoadingAddStakeholders(false);

  };
  const isFormComplete = name && description && logoFile && coverFile && selectedProducts.length > 0 && selectedCategory.length > 0;

  return (
    <>
      <AdminTopBar title="Stakeholders" userName="Jane Doe" userImage="./Images/STIlogo.svg" />
      <div className=' w-full'>
        <div className="flex justify-between items-center mb-4 p-4">
          <SearchFilterComponent />
          {/* Add Stakeholder Button */}
          <button onClick={openModal} className="bg-custom-green-1  text-custom-blue-1 font-normal w-auto py-2 px-6 flex items-center justify-center">
            <PlusIcon className="w-5 h-5 mr-1" aria-hidden="true" />
            Add Stakeholder
          </button>
        </div>
        <ModalComponent isOpen={isModalOpen} onClose={closeModal} >
          {/* Your form goes here */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-xl font-semibold ">Stakeholder Profile</h2>
            <p className="text-sm ">Add basic information about the stakeholder for a quick setup</p>
            <div className="  text-left ">
              {/* Upload Cover Photo */}
              <div>
                <label htmlFor="cover-photo" className="block text-sm mt-10 font-medium ">Upload Cover Photo *</label>
                <input type="file" onChange={handleCoverChange} id="cover-photo" className="mt-1 p-2 w-full border cursor-pointer" />
              </div>

              {/* Upload Logo */}
              <div>
                <label htmlFor="logo" className="block text-sm font-medium mt-5 ">Upload Logo *</label>
                <input type="file" onChange={handleLogoChange} id="logo" className="mt-2 p-2 w-full border cursor-pointer" />
              </div>

              {/* Name Field */}
              <div>
                <label htmlFor="name" className="block text-sm font-medium mt-5 ">Name *</label>
                <input type="text" id="name" value={name} onChange={handleNameChange} className="mt-1 p-2 w-full border text-black" />
              </div>

              {/* Products/Services Field */}
              <div>
                <label htmlFor="products-services" className="block text-sm mt-5 font-medium text-white">Products/Services *</label>
                <Select
                  id="products-services"
                  options={productOptions}
                  isMulti
                  onChange={setSelectedProducts}
                  className="mt-1 text-black "
                  value={selectedProducts}
                  styles={customStyles}
                />
              </div>
              {/* Products/Services Field */}
              <div>
                <label htmlFor="ecosystem" className="block text-sm mt-5 font-medium text-white">Ecosystem Category *</label>
                <Select
                  id="ecosystem"
                  options={stakeholerOptions}
                  isMulti
                  onChange={selectedOptions => setSelectedCategory(selectedOptions || [])} // corrected setter function
                  className="mt-1 text-black"
                  value={selectedCategory}
                  styles={customStyles}
                />
              </div>
              {/* Description Field */}
              <div>
                <label htmlFor="description" className="block text-sm font-medium mt-5 ">Description *</label>
                <textarea id="description" value={description} onChange={handleDescriptionChange} rows="4" className="mt-1 p-2 w-full border text-black"></textarea>
              </div>
            </div>
            {/* Submit Button */}
            <div className="mt-6">
              <button type="submit" disabled={!isFormComplete || loadingAddStakeholders} className={`py-2 px-8 ${isFormComplete ? 'bg-custom-green-1 text-custom-blue-1' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}>
                Create Profile
              </button>
            </div>
          </form>
        </ModalComponent>

        {/* Add Table  */}
        <div className="overflow-x-auto bg-white mx-4  shadow overflow-y-auto relative">
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-blue-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Name</th>
                <th className="bg-blue-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Description</th>
                <th className="bg-blue-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Product/Service</th>
                <th className="bg-blue-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Status</th>
              </tr>
            </thead>

            {/* Dynamic rows will be rendered here */}
            <tbody>
              {stakeholders.map((stakeholder) => (
                <tr key={stakeholder.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => handleRowClick(stakeholder.id)}>
                  <td className="border-dashed border-t border-gray-200">
                    <div className="flex items-center">
                      <img src={stakeholder.media.logo} alt={`${stakeholder.name} logo`} className="h-8 w-8 ll mr-2 ml-5" />
                      <span className="text-gray-700 px-2 py-3 flex items-center">{stakeholder.name}</span>
                    </div>
                  </td>
                  <td className="border-dashed border-t border-gray-200 px-6 py-3">
                    <span className="text-gray-700 overflow-hidden overflow-ellipsis whitespace-nowrap">{stakeholder.description}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200">
                    {/* Assuming products is an array */}
                    <span className="text-gray-700 px-6 py-3 flex items-center">{stakeholder.products.join(', ')}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200">
                    <span className="px-6 py-3 flex items-center">
                      <span className={`h-4 w-4 ll inline-block mr-3 ${stakeholder.status ? 'bg-green-400' : 'bg-red-400'}`}></span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>

          {/* add a spinner */}
          {loadingStakeholders && (
            <div className="flex justify-center items-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
            </div>
          )}

        </div>
      </div >
    </>
  );
};

export default StakeholdersContent;