// Financials.js
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../Services/firebase-config';
import { BsTrash } from 'react-icons/bs';
import Loading from '../../Common/Loading';
import TableRow from '../../Common/TableRow';

const Financials = () => {
  
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [funding, setFunding] = useState([]);
  const [taxRemitted, setTaxRemitted] = useState([]);


  const addFunding = async () => {
    const data = { date: '', product: '', fundingVolume: '', productionVolume: '' };
    try {
      await addDoc(collection(db, "stakeholders", id, 'funding'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };


  const addTaxRemitted = async () => {
    const data = { date: '', typeOfTax: '', amount: '' } ;
    try {
      await addDoc(collection(db, "stakeholders", id, 'taxRemitted'), data);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const fetchFunding = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "funding"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setFunding(data);
    });

    return unsubscribe;
  };

  const fetchTaxRemitted = () => {
    setLoading(true);
    const unsubscribe = onSnapshot(collection(db, "stakeholders", id, "taxRemitted"), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLoading(false);
      setTaxRemitted(data);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchFunding();
    const unsubscribe2 = fetchTaxRemitted();
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <div className='w-full bg-white p-8'>
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Financials</h1>
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">Funding</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-custom-blue-1">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Type of Funding
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                Amount
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {funding.map((entry, index) => (
              <FundingRow key={index} {...{ entry }} stakeholderId={id} />
            ))}
          </tbody>
        </table>

        <Loading loading={loading} />

        <button onClick={addFunding} className="bg-custom-green-1 text-white px-4 py-2 ">
          Add Row
        </button>
      </div>

      {/* Below your financial entries table */}
      <h2 className="text-xl font-bold text-gray-800 mt-8 mb-4">Tax Remitted</h2>
      <table className="min-w-full divide-y divide-gray-200 bg-white">
        <thead className="bg-custom-blue-1">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Type of Tax</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {taxRemitted.map((entry, index) => (
            <TaxRemittedRow key={index} {...{ entry }} stakeholderId={id} />
          ))}
        </tbody>
      </table>

      <Loading loading={loading} />

      <button onClick={addTaxRemitted} className="mt-4 bg-custom-green-1 text-white px-4 py-2">
        Add Row
      </button>

    </div>
  );
};


const FundingRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'funding', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <tr>
        <td className="">
          <input
            type="date"
            name='date'
            className="w-full text-sm px-6 py-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <select
            className="w-full text-sm px-6 py-4"
            name='typeOfFunding'
            defaultValue={entry.typeOfFunding}
            onBlur={updateData}
          >
            <option value="Funding Raised">Funding Raised</option>
            <option value="Debt Financing">Debt Financing</option>
            <option value="Equity">Equity</option>
          </select>
        </td>
        <td className="">
          <input
            type="number"
            name='amount'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.amount}
            onBlur={updateData}
          />
        </td>
        <td>
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </td>
      </tr>)}
  </TableRow>
}


const TaxRemittedRow = ({ entry, stakeholderId }) => {
  const docRef = doc(db, 'stakeholders', stakeholderId, 'taxRemitted', entry.id);

  return <TableRow docRef={docRef} entry={entry}>
    {(updateData, deleteData) => (
      <tr>
        <td className="">
          <input
            type="date"
            name='date'
            className="w-full text-sm px-6 py-4"
            defaultValue={entry.date}
            onBlur={updateData}
          />
        </td>
        <td className="">
          <select
            className="w-full text-sm px-6 py-4"
            name='typeOfTax'
            defaultValue={entry.typeOfFunding}
            onBlur={updateData}
          >
            <option value="Funding Raised">Funding Raised</option>
            <option value="Debt Financing">Debt Financing</option>
            <option value="Equity">Equity</option>
          </select>
        </td>
        <td className="">
          <input
            type="number"
            name='amount'
            className="w-full text-sm px-2 py-4"
            defaultValue={entry.amount}
            onBlur={updateData}
          />
        </td>
        <td>
          <button
            onClick={deleteData}
            className="text-custom-blue-1 hover:text-custom-green-1"
          >
            <BsTrash className="text-xl " />
          </button>
        </td>
      </tr>)}
  </TableRow>
}

export default Financials;
