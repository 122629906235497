// StakeholderSideNav.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { HomeIcon, UsersIcon, ClipboardListIcon, CogIcon } from '@heroicons/react/outline';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../Services/firebase-config.js';
import { signOut } from 'firebase/auth'; // Import signOut function
import { auth } from "../../../Services/firebase-config";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const StakeholderSideNav = ({ children }) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      navigate('/'); // Redirect to the homepage after sign out
    } catch (error) {
      console.error("Logout failed", error);
      // Handle errors here, such as displaying a notification
    }
  };
  const { id } = useParams();
  const createPath = (path) => `/stakeholder/${id}/${path}`;
  const [stakeholder, setStakeholder] = useState(null);

  useEffect(() => {
    const fetchStakeholderData = async () => {
      const stakeholderRef = doc(db, 'stakeholders', id);
      const stakeholderSnap = await getDoc(stakeholderRef);

      if (stakeholderSnap.exists()) {
        setStakeholder(stakeholderSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchStakeholderData();
  }, [id]);

  return (
    <div className='flex h-screen  '>
      <div className="w-64 h-screen sticky top-0 bg-custom-blue-1 flex flex-col justify-between">
        <div>
          {stakeholder && (
            <>
              {/* Add the logo and date at the top */}
              <div className="relative w-full h-32 mb-10"> {/* Set a fixed height for your cover area */}
                <img src={stakeholder.media.cover} alt="Cover" className="absolute top-0 w-full h-32 object-cover" />
                {/* Profile Image and Name/Date Overlay */}
                <div className="absolute top-0 left-0 right-0 h-full bg-black bg-opacity-70 p-2 flex justify-between items-center"> {/* Overlay covering full width and height */}
                  <div className="relative mt-5 h-20 w-20"> {/* Profile image container */}
                    <img src={stakeholder.media.logo} alt={stakeholder.name} className="rounded-full border-4 border-white bg-white absolute bottom-0" style={{ transform: 'translateY(50%)' }} />
                  </div>
                  <div className="absolute bottom-0 left-24 "> {/* Text next to the logo */}
                    <p className="text-lg font-semibold text-white">{stakeholder.name}</p> {/* Changed text color for visibility */}
                    <p className="text-sm text-white">12/02/2024</p> {/* Changed text color for visibility */}
                  </div>
                </div>
              </div>
            </>
          )}
          <ul className="flex flex-col mt-5 space-y-2">
            <li>
              <NavLink to={createPath("details")} /*...*/
                end // add this prop here
                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <HomeIcon className="w-6 h-6" />
                <span>Overview</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={createPath("basicinfo")}
                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <UsersIcon className="w-6 h-6" />
                <span>Basic information</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={createPath("operations")}
                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <ClipboardListIcon className="w-6 h-6" />
                <span>Operations</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={createPath("sales")}

                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <CogIcon className="w-6 h-6" />
                <span>Sales</span>
              </NavLink>
              <NavLink to={createPath("humanresource")}

                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >

                <CogIcon className="w-6 h-6" />
                <span>Human Resources</span>
              </NavLink>
              <NavLink to={createPath("financials")}

                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <CogIcon className="w-6 h-6" />
                <span>Financials</span>
              </NavLink>
              <NavLink to={createPath("charginginfrastructure")}

                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <CogIcon className="w-6 h-6" />
                <span>Charging Infrastructure</span>
              </NavLink>
              <NavLink to={createPath("energy")}

                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <CogIcon className="w-6 h-6" />
                <span>Energy & Sustainability</span>
              </NavLink>
              <NavLink to={createPath("planning")}

                className={({ isActive }) =>
                  `flex items-center space-x-2 p-2 transition-colors ${isActive ? 'bg-custom-green-1 text-custom-blue-1' : 'hover:bg-custom-green-1 text-white'
                  }`
                }
              >
                <CogIcon className="w-6 h-6" />
                <span>Planning & Development</span>
              </NavLink>
            </li>
          </ul>
        </div>
        {/* Logout Button */}
        <div className="p-4 w-full flex justify-center items-end">
          <button
            onClick={handleLogout}
            className="flex items-center justify-center px-4 py-2 bg-transparent text-white  hover:bg-custom-green-1 ">
            {/* Replace the arrow icon with your logout icon */}
            <img src="./Images/icons/logout.svg" alt="e-bus" className="h-6 w-6 mr-2 " />
            LOG OUT
          </button>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default StakeholderSideNav;
