
import React, { useState } from 'react';
import EcosystemNav from './EcosystemNav';
import Slider from 'react-slick';

const EcosystemSection = () => {
  const [activeSlider, setActiveSlider] = useState('rnd'); // Default to 'rnd'

  const handleNavClick = (ecosystem) => {
    setActiveSlider(ecosystem);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // responsive settings if needed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  // Define your slider content for different categories
  const sliderContent = {
    rnd: [
      "./Images/ecosystem/kiira.svg",
      "./Images/ecosystem/zembo.svg",
      "./Images/ecosystem/gogo.svg",
      "./Images/ecosystem/spiro.svg",
      // ... other R&D images
    ],
    energy: [
      "./Images/ecosystem/gogo.svg",
      "./Images/ecosystem/spiro.svg",
      "./Images/ecosystem/kiira.svg",
      "./Images/ecosystem/zembo.svg",
      // ... other Energy images
    ],
    tnd: [
      "./Images/ecosystem/gogo.svg",
      "./Images/ecosystem/spiro.svg",
      "./Images/ecosystem/kiira.svg",
      "./Images/ecosystem/zembo.svg",
      // ... other Energy images
    ],
    financing: [
      "./Images/ecosystem/gogo.svg",
      "./Images/ecosystem/spiro.svg",
      "./Images/ecosystem/kiira.svg",
      "./Images/ecosystem/zembo.svg",
      // ... other Energy images
    ],
    df: [
      "./Images/ecosystem/gogo.svg",
      "./Images/ecosystem/spiro.svg",
      "./Images/ecosystem/kiira.svg",
      "./Images/ecosystem/zembo.svg",
      // ... other Energy images
    ],
    policy: [
      "./Images/ecosystem/gogo.svg",
      "./Images/ecosystem/spiro.svg",
      "./Images/ecosystem/kiira.svg",
      "./Images/ecosystem/zembo.svg",
      // ... other Energy images
    ],
 
    // ... other categories
  };
  // Render the slider with images based on the active state
  const renderSlider = (images) => {
    return (
      <Slider {...settings}>
        {images.map((src, index) => (
          <div key={index} className="outline-none focus:outline-none">
            <img src={src} alt="Ecosystem player" />
          </div>
        ))}
      </Slider>
    );
  };

  return (
    <section className="bg-white p-10">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl md:text-4xl text-custom-blue-1 font-bold mb-4">E-MOBILITY ECOSYSTEM PLAYERS</h2>
        <EcosystemNav onNavClick={handleNavClick} />
        {renderSlider(sliderContent[activeSlider])}

        <button className="bg-custom-green-1  text-custom-blue-1 font-normal py-2 px-12 inline-flex items-center justify-center mt-10">
          Join our Ecosystem
          <svg className="ml-2 w-4 h-4" width="20" height="20" strokeWidth="1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6699 3.35587L15.2297 3.79546L7.31518 11.7106L6.875 12.1502L7.75536 13.0305L8.19554 12.5904L16.1101 4.67583L16.5503 4.23565L15.6699 3.35587ZM5.55678 13.469L5.1166 13.9092L3.35762 15.6681L2.91744 16.1083L3.79781 16.9881L4.23799 16.5479L5.99697 14.7889L6.43715 14.3488L5.55678 13.469Z" fill="#2A3950" />
            <path d="M6.6931 3.17398V4.41873H7.31576H15.4874V12.5904V13.2125H16.7322V12.5904V3.17398H7.31576H6.6931Z" fill="#2A3950" />
          </svg>
        </button>

      </div>
    </section>
  );
};

export default EcosystemSection;
