// ReportsContent.js
import React from 'react';

const ReportsContent = () => {
  return (
    <div>
      {/* Your dashboard content here */}
      <h1 className="text-2xl font-bold">ReportsContent</h1>
      {/* Other content components */}
    </div>
  );
};

export default ReportsContent;