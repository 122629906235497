// Operations.js
import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { BsPlusCircle, BsTrash } from 'react-icons/bs'; // Make sure to install react-icons if you haven't already
import { db } from '../../../Services/firebase-config';
import { useParams } from 'react-router-dom';

const Operations = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([{ name: '', localContent: '' }]);
  const [valueChainActivities, setValueChainActivities] = useState('');
  const [keyPartners, setKeyPartners] = useState('');
  const [facilities, setFacilities] = useState([{ name: '', location: '', capacity: '' }]);
  const [updatedData, setUpdatedData] = useState(null);
  const [loading, setLoading] = useState(false);

  const addProduct = () => {
    setProducts([...products, { name: '', localContent: '' }]);
  };

  const handleProductChange = (index, type, value) => {
    const updatedProducts = products.map((product, idx) => {
      if (idx === index) {
        return { ...product, [type]: value };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const deleteProduct = (index) => {
    setProducts(products.filter((_, idx) => idx !== index));
  };
  const addFacility = () => {
    setFacilities([...facilities, { name: '', location: '', capacity: '' }]);
  };

  const removeFacility = (index) => {
    setFacilities(facilities.filter((_, idx) => idx !== index));
  };

  const handleFacilityChange = (index, field, value) => {
    const updatedFacilities = facilities.map((facility, idx) => {
      if (idx === index) {
        return { ...facility, [field]: value };
      }
      return facility;
    });
    setFacilities(updatedFacilities);
  };


  const handleSubmit = async (e) => {
    return;
    e.preventDefault();
    setLoading(true);
    const docRef = doc(db, 'stakeholders', id, 'operations');

    try {
      await updateDoc(docRef, updatedData);
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    setLoading(false);
  };


  return (
    <div className='w-full p-8 bg-white'>
      <h1 className="text-2xl font-bold mb-6">Operations</h1>

      <form className="space-y-4" onSubmit={handleSubmit}>
        {/* Value Chain Activities */}
        <div className="mb-6">
          <label htmlFor="valueChainActivities" className="text-gray-700 block mb-2">Value Chain Activities</label>
          <textarea
            id="valueChainActivities"
            className="w-full p-2 border border-gray-300"
            rows="4"
            placeholder="Describe the value chain activities..."
            value={valueChainActivities}
            onChange={(e) => setValueChainActivities(e.target.value)}
          />
        </div>

        {/* Key Partners */}
        <div className="mb-6">
          <label htmlFor="keyPartners" className="text-gray-700 block mb-2">Key Partners</label>
          <textarea
            id="keyPartners"
            className="w-full p-2 border border-gray-300"
            rows="4"
            placeholder="List your key partners..."
            value={keyPartners}
            onChange={(e) => setKeyPartners(e.target.value)}
          />
        </div>

        {/* Products and Services */}
        <h2 className="text-gray-700 block mb-2">Products and Services</h2>
        {products.map((product, index) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-2 items-center">
            <input
              type="text"
              placeholder="Name"
              className="p-2 border border-gray-300 col-span-2"
              value={product.name}
              onChange={(e) => handleProductChange(index, 'name', e.target.value)}
            />
            <input
              type="text"
              placeholder="%Local Content"
              className="p-2 border border-gray-300 col-span-1"
              value={product.localContent}
              onChange={(e) => handleProductChange(index, 'localContent', e.target.value)}
            />
            <div className="flex ">
              {products.length > 1 && (
                <button
                  onClick={() => deleteProduct(index)}
                  className="text-custom-blue-1 hover:text-custom-green-1"
                >
                  <BsTrash className="text-xl" />
                </button>
              )}
              {index === products.length - 1 && (
                <button
                  onClick={addProduct}
                  className="text-custom-blue-1 hover:text-custom-green-1 ml-2"
                >
                  <BsPlusCircle className="text-xl" />
                </button>
              )}
            </div>
          </div>
        ))}
        <h2 className="text-xl font-semibold mb-4">Production Facilities</h2>
        {facilities.map((facility, index) => (
          <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-2 items-center">
            <input
              type="text"
              placeholder="Name"
              className="p-2 border border-gray-300 col-span-1"
              value={facility.name}
              onChange={(e) => handleFacilityChange(index, 'name', e.target.value)}
            />
            <input
              type="text"
              placeholder="Location"
              className="p-2 border border-gray-300 col-span-1"
              value={facility.location}
              onChange={(e) => handleFacilityChange(index, 'location', e.target.value)}
            />
            <input
              type="text"
              placeholder="Production Capacity"
              className="p-2 border border-gray-300 col-span-1"
              value={facility.capacity}
              onChange={(e) => handleFacilityChange(index, 'capacity', e.target.value)}
            />
            <div className="flex justify-start col-span-1">
              <button
                onClick={() => removeFacility(index)}
                className="text-custom-blue-1 hover:text-custom-green-1"
              >
                <BsTrash className="text-xl " />
              </button>
              <button
                onClick={addFacility}
                className=" text-custom-blue-1 hover:text-custom-green-1 ml-4 "
              >
                <BsPlusCircle className="mr-2" />
              </button>
            </div>
          </div>
        ))}

        <div className="flex justify-center mt-4">
          <button
            type="button"
            className="py-2 px-6 border border-custom-blue-1 text-custom-blue-1 hover:bg-gray-100 "
          >
            Discard Changes
          </button>
          <button
            type="submit"
            className="py-2 px-6 ml-10 bg-custom-green-1 text-white  hover:bg-custom-green-1"
          >
            Save Changes
          </button>
        </div>
      </form>

    </div>
  );
};

export default Operations;
