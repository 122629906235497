import React, { useState } from 'react'; // Add useState here
import { NavLink } from 'react-router-dom';
import { MenuIcon } from '@heroicons/react/outline'; // If you're using Heroicons
import { XIcon } from '@heroicons/react/outline'; // If you're using Heroicons


// TopMenu.js
const TopMenu = ({ transparent }) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const CustomNavLink = ({ children, to }) => {
    return <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? `px-3 py-2 border-b-2 border-custom-green-1 ${transparent && 'text-white'}`
          : `px-3 py-2  cursor-pointer ${transparent ? 'text-white hover:text-gray-300' : 'hover:text-gray-500'}`
      }
      end
    >
      {children}
    </NavLink>
  }

  return (
    <nav className={`w-full ${transparent ? 'absolute bg-custom-blue-1 bg-opacity-60' : 'bg-white'}  p-4 z-10`}>
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo on the left */}
        <img src={`./Images/${transparent ? 'Logo-light' : 'Logo'}.svg`} alt="Logo" className="h-10 text-red-600" />

        <div className="flex-grow justify-center hidden md:flex space-x-4">
          {/* Centered menu items */}
          <ul className={`${isOpen ? 'flex' : 'hidden'
            } md:flex grow justify-center space-x-4`}>

            <CustomNavLink to="/">Home</CustomNavLink>
            <CustomNavLink to="/charging-network">Charging Network</CustomNavLink>
            <CustomNavLink to="/reports">Resources</CustomNavLink>
            <CustomNavLink to="/ebus">E-Bus Drivers Program</CustomNavLink>
            {/* <CustomNavLink to="/uploadDrivers">Upload</CustomNavLink> */}
          </ul>
          {/* Login button on the right */}
          <NavLink className="button-primary" to="/login">
            Login
          </NavLink>
        </div>

        {/* Hamburger Icon */}
        <div className="md:hidden flex items-center" onClick={toggleMenu}>
          <MenuIcon className="h-6 w-6" />
        </div>

        {isOpen && (

          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-10 transition-opacity duration-300" onClick={toggleMenu}></div>
            <div className="fixed top-0 right-0 w-[75%] max-w-sm h-full bg-custom-blue-1 shadow-md z-20 transform transition-transform duration-300">
              <div className="flex justify-end mb-4">
                <button onClick={toggleMenu} aria-label="Close menu">
                  <XIcon className="h-6 w-6 text-white" /> {/* Replace with actual close icon */}
                </button>
              </div>

              <ul className="flex flex-col text-white  p-4">
                <CustomNavLink to="/">Home</CustomNavLink>
                <CustomNavLink to="/charging-network">Charging Network</CustomNavLink>
                <CustomNavLink to="/reports">Resources</CustomNavLink>
              </ul>
            </div>
          </>
        )}


      </div>
    </nav>
  );
};


export default TopMenu;
