// ModalComponent.js
import React from 'react';
import { XIcon } from '@heroicons/react/solid';

const ModalComponent = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-30 z-40" onClick={onClose}></div>
      {/* Modal content */}
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="relative bg-custom-blue-1 text-white shadow-xl p-6 w-full max-w-md m-auto">
            {/* Close Button */}
            <button onClick={(event) => {
              event.stopPropagation(); // Stop the click event from propagating to parent elements
              event.preventDefault(); // Prevent any default action associated with the click event
              onClose(); // Close the modal
            }} className="absolute top-3 right-3">
              <XIcon className="w-5 h-5 text-white ml-1" />
            </button>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
