// Home.js
import React from 'react';
import HeroSection from '../Components/Common/HeroSection';
import MetricsSection from '../Components/Common/MetricsSection';
import Footer from '../Components/Common/Footer';
import EcosystemSection from '../Components/Common/EcosystemSection';
import TopMenu from '../Components/Common/TopMenu';

const Home = () => {
  return (
    <div className="home">
      <TopMenu transparent />
      <HeroSection />
      <MetricsSection/>
      <EcosystemSection/>
      <Footer/>
    </div>
  );
};

export default Home;
