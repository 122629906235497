// src/EcosystemNav.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const EcosystemNav = ({ onNavClick }) => {
  return (
    <nav className="text-custom-blue-1 p-4 mb-10 hidden md:block">
      <ul className="flex justify-around text-sm lg:text-xl xl:text-2xl space-x-4">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'border-b-2 border-custom-green-1 pb-1' : undefined
            }
            onClick={() => onNavClick('rnd')}
          >
            R&D & Manufacturing
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'border-b-2 border-custom-green-1 pb-1' : undefined
            }
            onClick={() => onNavClick('energy')}
          >
            Energy
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'border-b-2 border-custom-green-1 pb-1' : undefined
            }
            onClick={() => onNavClick('tnd')}
          >
            Transport & Distribution
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'border-b-2 border-custom-green-1 pb-1' : undefined
            }
            onClick={() => onNavClick('financing')}
          >
            Financing
          </NavLink>
        </li>
        <li>
          <NavLink
            to="#"
            className={({ isActive }) =>
              isActive ? 'border-b-2 border-custom-green-1 pb-1' : undefined
            }
            onClick={() => onNavClick('df')}
          >
            Digital Infrastructure
          </NavLink>
        </li>
        <li>
          <NavLink
            to="#"
            className={({ isActive }) =>
              isActive ? 'border-b-2 border-custom-green-1 pb-1' : undefined
            }
            onClick={() => onNavClick('policy')}
          >
            Policy
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default EcosystemNav;
