
import React from 'react';

const HeroSection = () => {
  return (
    <div className="h-screen bg-cover bg-center flex flex-col justify-center items-center" style={{ backgroundImage: `url('/Images/background.jpg')` }}>
      <h1 className="text-custom-blue-1 text-4xl md:text-5xl font-bold text-center mb-4">UGANDA E-MOBILITY DATA BANK</h1>
      <button className="bg-custom-green-1  text-custom-blue-1 font-normal w-auto py-2 px-12 flex items-center justify-center ">
        Join our Ecosystem
        <svg className="ml-2 w-4 h-4" width="20" height="20" strokeWidth="1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.6699 3.35587L15.2297 3.79546L7.31518 11.7106L6.875 12.1502L7.75536 13.0305L8.19554 12.5904L16.1101 4.67583L16.5503 4.23565L15.6699 3.35587ZM5.55678 13.469L5.1166 13.9092L3.35762 15.6681L2.91744 16.1083L3.79781 16.9881L4.23799 16.5479L5.99697 14.7889L6.43715 14.3488L5.55678 13.469Z" fill="#2A3950" />
          <path d="M6.6931 3.17398V4.41873H7.31576H15.4874V12.5904V13.2125H16.7322V12.5904V3.17398H7.31576H6.6931Z" fill="#2A3950" />
        </svg>

      </button>

    </div>
  );
};

export default HeroSection;
